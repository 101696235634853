<template>
  <div class="navbar-container">
    <ChooseProjectForUploadModal :onCloseModal="onCloseUploadModal" v-if="isChooseProjectModalShowing" />
    <Navbar :onToggleMenu="onSwitchToggleMenu" :user="currentUser" class="navbar-byteark">
      <!-- navbar will show on mobile only -->
      <template v-slot:navbar-brand>
        <router-link class="navbar-brand" :to="`/${teamName}`">
          <img src="@/assets/images/logo_120.png" class="logo" alt="ByteArk Stream" />
          <span class="brand">Stream</span>
        </router-link>
      </template>
      <template v-slot:navbar-nav-actions>
        <div class="navbar-nav navbar-nav-actions">
          <Button type="button" buttonStyle="text-primary-light" @click.stop="onChangeStateUploadModal">
            <template v-slot:icon-prefix>
              <i class="fas fa-cloud-arrow-up"></i>
            </template>
          </Button>
        </div>
      </template>
      <!-- Addon Menu -->
      <template v-slot:navbar-nav>
        <ul class="navbar-nav" v-if="!isManagementPage()">
          <li class="nav-item">
            <Button type="button" buttonStyle="text-secondary-light" buttonClasses="btn-upload"
                    @click.stop="onChangeStateUploadModal">
              <template v-slot:icon-prefix>
                <i class="nav-icon fas fa-cloud-arrow-up"></i>
              </template>
              <template v-slot:content> Upload </template>
            </Button>
          </li>
          <li class="nav-item">
            <Button buttonStyle="text-secondary-light" buttonClasses="nav-link" :url="`/${teamName}/manage`">
              <template v-slot:content> Manage </template>
            </Button>
          </li>
        </ul>
      </template>
      <!-- User -->
      <template v-slot:navbar-nav-user>
        <ul :class="{ transparent: isUserLoading }" class="navbar-nav navbar-nav-user"
            v-click-outside="onCloseShowUserMenu">
          <li class="nav-item">
            <div class="user-container" @click.stop="onToggleShowUserMenu">
              <Logo :class="{ transparent: !currentUser }" v-if="currentUser" :user="currentUser" />
            </div>
            <!-- submenu -->
            <div class="sub-menu" v-if="isUserMenuShowing">
              <div v-if="currentUser.team">
                <AccountInformationSection nav />
                <div class="nav-item-token">
                  <router-link @click="isUserMenuShowing = false" :to="`/${teamName}/me/personal-access-token`">
                    <i class="fas fa-key"></i> Personal Access Token
                  </router-link>
                </div>
                <ManageAccountLanguage />
              </div>
              <a class="nav-item item-logout" :href="`${baseURL}/accounts/logout?team=${teamName}`">
                <i class="fas fa-right-from-bracket"></i>&nbsp;{{ $t('common:button.logout') }}
              </a>
            </div>
          </li>
        </ul>
      </template>
    </Navbar>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import Logo from '@/modules/shared/components/atoms/logo/Logo.vue';
import Navbar from '@/modules/shared/components/molecules/navbar/Navbar.vue';
import ManageAccountLanguage from '@/components/molecules/manageAccountLanguage/ManageAccountLanguage.vue';
import AccountInformationSection from '@/components/organisms/accountInformationSection/AccountInformationSection.vue';
import { ChooseProjectForUploadModal } from '@/modules/projectVideoList';
import Button from '@/modules/shared/components/atoms/button/Button.vue';

export default {
  props: {
    onToggleSidebar: {
      type: Function,
      default: () => { },
    },
  },
  components: {
    Logo,
    Navbar,
    ManageAccountLanguage,
    AccountInformationSection,
    ChooseProjectForUploadModal,
    Button,
  },
  data() {
    return {
      isUserMenuShowing: false,
    };
  },
  computed: {
    ...mapState({
      currentUser: (state) => state.user.currentUser,
      isChooseProjectModalShowing: (state) => state.videoUpload.isChooseProjectForUploadModalShowing,
      isUserLoading: (state) => state.user.isLoading,
    }),
    baseURL() {
      return process.env.VUE_APP_ORIGIN || window.location.origin;
    },
    teamName() {
      return this.$route.params.teamName;
    },
    route() {
      return this.$route;
    },
  },
  watch: {
    route: {
      handler() {
        this.onCloseUploadModal();
      },
      deep: true,
    },
  },
  methods: {
    isManagementPage() {
      const { fullPath } = this.$route;
      return fullPath.includes('manage') && !fullPath.includes('projects');
    },
    onCloseToggleMenu() {
      this.onToggleMenu(false);
    },
    onChangeStateUploadModal() {
      this.$store.dispatch(
        'setChooseProjectForUploadModalShowing',
        !this.isChooseProjectModalShowing,
      );
    },
    onCloseUploadModal() {
      this.$store.dispatch('setChooseProjectForUploadModalShowing', false);
    },
    onSwitchToggleMenu() {
      this.onToggleSidebar();
    },
    onCloseShowUserMenu() {
      this.isUserMenuShowing = false;
    },
    onToggleShowUserMenu() {
      this.isUserMenuShowing = !this.isUserMenuShowing;
    },
    onChangeLang(lang) {
      this.$store.dispatch('updateCurrentUserLanguage', lang);
      this.$i18n.locale = lang;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/global-variables.scss';
@import '~@/assets/scss/breakpoint.scss';

.navbar-byteark {
  border: none;
  color: $body-color;
  background-color: $bg-navy;
  padding: $spacing-base * 0.75 $spacing-base * 1.5;

  .navbar-brand {
    //font-family: $font-family-Rubik;
    //font-weight: $font-weight-bold;
    font-size: 1.09375rem;
    color: white;

    display: flex;
    align-items: flex-start;

    .logo {
      height: 24px;
      margin-right: $spacing-base * 0.5;
      margin-top: -2px;
    }
  }
}

.navbar-nav-user {
  margin-right: 0;
  padding: 0;

  .nav-item {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    color: white;

    .logo {
      width: 36px;
      height: 36px;
      border-radius: 50%;
      background-color: $grey-700;
      color: $grey-200;

      display: flex;
      align-items: center;
      justify-content: center;

      text-transform: uppercase;
      margin-left: $spacing-base * 0.5;

      font-size: $font-size-base * 1.25;
    }
  }
}

.navbar-nav {
  display: flex;
  align-items: center;
  margin-left: auto;
  margin-right: $spacing-base;
  margin: 0 $spacing-base 0 auto;

  >.nav-item {
    position: relative;
    cursor: pointer;

    &:hover {
      text-decoration: none;
      color: white;
    }

    > .btn, > a {
      color: $grey-300;
      padding: $spacing-base * 0.5 $spacing-base;
      transition: all 0.5s ease-out;
      border-radius: 4px;

      &:hover, &.router-link-active {
        background-color: lighten($bg-navy, 5%);
        text-decoration: none;
        color: white;
      }
    }

    .sub-menu {
      position: absolute;
      top: 42px;
      right: -10px;
      min-width: 220px;
      background-color: white;
      border: 1px solid $grey-200;
      border-radius: 4px;
      z-index: 102;
      box-shadow: 0 0 12px rgba(0, 0, 0, 0.2);

      .nav-item {
        padding: $spacing-base * 0.75 $spacing-base;
        display: block;
        font-size: $font-size-base;
        text-align: left;
        color: $grey-700;
        border-bottom: 1px solid $grey-300;
        transition: all 0.2s ease-out;

        &:last-child {
          border-bottom: 0;
        }

        &:hover, &.router-link-active {
          background-color: $grey-200;
          border-radius: 0;
        }

        &.item-logout {
          margin-right: 0;
        }
      }

      .user-item, .menu-item {
        padding-bottom: $spacing-base * 0.25;

        label {
          font-size: $font-level-8 * 0.875;
          color: $grey-600;
          margin-bottom: 0;
        }

        .user-item-value {
          font-size: $font-level-8;
        }

        &:last-child {
          padding-bottom: 0;
        }
      }
    }
  }

  &.navbar-nav-actions {
    display: none;
  }
}
  :deep(.btn-upload), :deep(.nav-link) {
    // line-height: 1.4;
    // padding: 0.3rem $spacing-base;
  }

.navbar-nav {
  .nav-item {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 26px;
    margin-right: 2px;

    .nav-icon {
      // margin-right: 6px;
    }

    .nav-link {
      line-height: 1.1;
    }

    &.nav-primary {
      :deep(.btn-upload) {
        // line-height: 1.4;
        // padding: 0.3rem $spacing-base;
      }

      &::after {
        display: inline-block;
        position: relative;
        content: '';
        width: 2px;
        height: 26px;
        top: 0;
        margin: 0 6px 0 0;
        background: #323d46;
      }
    }
  }

  .sub-menu {
    .nav-item {
      height: auto;
    }
  }
}

.user-container {
  display: flex;
  align-items: center;
  min-width: 44px;
}

.nav-item-token {
  text-align: center;
  margin-top: 6px;
  margin-bottom: 14px;

  a {
    border: 1px solid #dadce0;
    padding: 8px 16px;
    border-radius: 100px;
    color: $body-color;
    text-decoration: none;
    font-size: 12px;
    transition: all 250ms ease;

    i {
      margin-right: 4px;
    }

    &:hover {
      text-decoration: none;
      background-color: $grey-200;
    }
  }
}

:deep(.navbar-toggler) {
  display: none;
}

.transparent {
  opacity: 0;
}

@media screen and (max-width: $max-layout-lg) {
  .navbar-nav {
    margin: 0 0 0 auto;
  }

  .navbar-byteark {
    padding: $spacing-base * 0.75 $spacing-base;
  }
}

// Medium devices (tablets, less than 992px)
@media screen and (max-width: $max-layout-lg) {
  .navbar-byteark {
    padding: $spacing-base * 0.5 $spacing-base;

    :deep(.navbar-toggler) {
      color: white;
      display: block;
    }

    .navbar-brand {
      flex: 1 0 auto;
      margin: 0;
      text-align: center;
      color: white;

      display: flex;
      justify-content: center;
      align-items: center;

      .logo,
      img {
        max-width: 28px;
        margin-right: 8px;
      }
    }

    .navbar-nav-actions {
      flex: 0 0 40px;
      display: flex;
      margin-right: 0;
    }

    .navbar-nav-user {
      display: none;
    }
  }
}
</style>
