<template>
  <div v-click-outside="onClose" class="aside-container">
    <Button @click.stop="onClose" buttonStyle="text-secondary" type="button" buttonClasses="btn-close">
      <template v-slot:icon-prefix>
        <i class="fas fa-xmark"></i>
      </template>
    </Button>
    <div v-if="isManageMenuShowing" class="menu-header">
      <Button @click.stop="onToggleManageMenu" buttonStyle="text-secondary" type="button" buttonClasses="btn-back">
        <template v-slot:content>
          Management
        </template>
        <template v-slot:icon-prefix>
          <i class="fas fa-chevron-left"></i>
        </template>
      </Button>
    </div>
    <div class="aside-menu-container" :class="{
      'reduced-menu-height': userMenu,
      'no-padding': isManageMenuShowing,
    }">
      <div v-if="!isManageMenuShowing" class="aside-brand">
        <div class="logo-wrapper" @click="onClickLogo">
          <img src="../../../assets/images/logo_120.png" class="logo" />
        </div>
        <div class="brand" @click="onClickLogo">Stream</div>
      </div>
      <div v-if="!isManageMenuShowing" class="menu-group-container">
        <router-link class="main-menu-item menu-item" :to="`/${teamName}/projects`">
          <i class="fas fa-briefcase icon"></i>
          <span class="title">Projects</span>
        </router-link>
        <div class="main-menu-item menu-item" @click.stop="onChangeStateUploadModal">
          <i class="fas fa-cloud-arrow-up icon"></i>
          <span class="title">Upload</span>
        </div>
        <div class="main-menu-item menu-item" @click.stop="onToggleManageMenu">
          <i class="fas fa-list-check icon" />
          <span class="title">Manage</span>
          <i class="fas fa-angle-right with-submenu" />
        </div>
      </div>
      <TeamManagementSidebar v-if="isManageMenuShowing" />
    </div>
    <div class="aside-user" :class="{ 'aside-user-toggle': userMenu }" v-if="currentUser">
      <div class="user-container" @click="onToggleUserMenu">
        <Logo :user="currentUser" />
        <div class="user-information">
          <div class="username">
            {{ currentUser.name }}
          </div>
        </div>
        <i class="fa fa-chevron-up chevron-icon ml-auto mr-2" :class="{ toggle: userMenu }" />
      </div>
      <div class="menu-container" v-if="userMenu">
        <div class="nav-item-token">
          <router-link :to="`/${teamName}/me/personal-access-token`">
            <i class="fas fa-key"></i> Personal Access Token
          </router-link>
        </div>
        <ManageAccountLanguage />
        <div class="menu-item" @click="onClickSignOut">
          <i class="fa fa-arrow-right-from-bracket mr-2"></i>
          <div class="title">{{ $t('common:button.logout') }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import TeamManagementSidebar from '@/components/organisms/teamManagementSidebar/TeamManagementSidebar.vue';
import ManageAccountLanguage from '@/components/molecules/manageAccountLanguage/ManageAccountLanguage.vue';
import Button from '@/modules/shared/components/atoms/button/Button.vue';
import Logo from '@/modules/shared/components/atoms/logo/Logo.vue';

export default {
  props: {
    onToggleSidebar: {
      type: Function,
      required: true,
    },
  },
  components: {
    Logo,
    ManageAccountLanguage,
    TeamManagementSidebar,
    Button,
  },
  computed: {
    ...mapState({
      currentUser: (state) => state.user.currentUser,
      isChooseProjectForUploadModalShowing: (state) => state.videoUpload?.isChooseProjectForUploadModalShowing,
    }),
    baseURL() {
      return process.env.VUE_APP_ORIGIN || window.location.origin;
    },
    teamName() {
      return this.$route.params.teamName;
    },
    fullPath() {
      return this.$route.fullPath;
    },
    isInsideManagementPage() {
      return this.fullPath.includes('/manage');
    },
  },
  data() {
    return {
      userMenu: false,
      isManageMenuShowing: false,
    };
  },
  watch: {
    fullPath() {
      this.onClose();
    },
  },
  created() {
    if (this.isInsideManagementPage) {
      this.isManageMenuShowing = true;
    }
  },
  methods: {
    async onClickSignOut() {
      try {
        // await logout();
        window.location.href = `${this.baseURL}/accounts/logout?team=${this.teamName}`;
        this.$store.dispatch('destoryCurrentUser');
      } catch (error) {
        // Do something
      }
    },
    onToggleUserMenu() {
      this.userMenu = !this.userMenu;
    },
    onClose() {
      this.onToggleSidebar();
    },
    onClickLogo() {
      if (this.$route.name !== 'projects') {
        this.$router.push(`/${this.teamName}/projects`);
      }
      this.onToggleSidebar();
    },
    onChangeStateUploadModal() {
      this.onClose();
      this.$store.dispatch('setChooseProjectForUploadModalShowing', !this.isChooseProjectForUploadModalShowing);
    },
    onToggleManageMenu() {
      this.isManageMenuShowing = !this.isManageMenuShowing;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/global-variables.scss';
@import '~@/assets/scss/themes/helpers';
@import '~@/assets/scss/breakpoint.scss';

.aside-container {
  z-index: 2000;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  width: 80vw;
  height: 100%;

  display: flex;
  flex-direction: column;

  background-color: $grey-100;
  box-shadow: 0 1rem 1rem rgba(0, 0, 0, 0.15) !important;
  animation: slide-left 200ms ease-out;

  :deep(.btn-close) {
    position: absolute;
    font-size: $font-level-5;
    top: 24px;
    right: 8px;
    color: $grey-600;
    z-index: 2001;
  }

  .aside-brand {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: $spacing-base * 1.5 $spacing-base * 0.5;
    //font-family: $font-family-Rubik;
    //font-weight: $font-weight-bold;
    font-size: $font-size-base * 1.45;
    color: $bg-navy;

    .logo {
      width: 30px;
      /* margin-bottom: $spacing-base * 0.55; */
      margin-right: $spacing-base;
    }
  }

  .aside-menu-container {
    @extend .scrollbar;
    padding: 0 $spacing-base $spacing-base;
    overflow-y: auto;
    height: 100%;

    .main-menu-container {
      display: flex;
      justify-content: space-around;
      flex: 1;
      padding-bottom: $spacing-base * 1.5;
      border-bottom: 1px solid $grey-300;

      .main-menu-item {
        flex-direction: column !important;
        padding: $spacing-base !important;
        font-weight: $font-weight-bold;
        width: 100%;

        .main-icon {
          width: 30px;
          margin-right: 0;
          margin-bottom: $spacing-base * 0.5;
          font-size: $font-size-base * 2;
        }
      }
    }

    .menu-group-container {
      margin-top: $spacing-base * 1.25;

      .menu-header {
        font-size: $font-size-base * 0.875;
        padding: $spacing-base * 0.5;
      }

      .menu-item {
        cursor: pointer;
        padding: $spacing-base $spacing-base * 0.5;
        text-decoration: none;

        display: flex;
        align-items: center;
        flex-direction: row;
        font-size: $font-size-base * 1.2;
        color: $grey-800;

        transition: background-position 0.2s;
        background-size: 200% 200%;
        background-image: linear-gradient(90deg, $bg-blue 50%, transparent 0);
        background-position: right;
        border-bottom: 1px solid $grey-200;

        .icon {
          color: $grey-600;
          margin-right: $spacing-base * 0.75;
          font-size: $font-size-base * 1.125;
          width: 20px;
        }

        .with-submenu {
          margin-left: auto;
          margin-right: 2px;
          color: $grey-600;
        }

        &:hover,
        &.router-link-active {
          text-decoration: none;
          color: $grey-900;
          background-position: left;
          border-radius: 3px;

          .icon {
            color: $bg-navy;
          }
        }
      }
    }

    .menu-group-container:last-child {
      margin-bottom: 0;
    }

    &.no-padding {
      padding: 0;
    }
  }

  .aside-user {
    display: flex;
    flex-direction: column;
    border-top: 0.5px solid $grey-300;
    background-color: $grey-100;
    width: 100%;

    .user-container {
      display: flex;
      align-items: center;
      width: 100%;
      cursor: pointer;
      padding: $spacing-base $spacing-base * 1.25;
      transition: all 0.2s ease-out;

      .user-information {
        margin-left: $spacing-base * 0.5;
        margin-right: auto;
        display: flex;
        max-width: 45%;
        overflow: hidden;

        .username {
          font-size: $font-size-base * 1.2;
          color: $grey-800;
          text-overflow: ellipsis;
          overflow: hidden;
          font-weight: $font-weight-base;
        }
      }

      .role {
        margin-left: auto;
      }

      .chevron-icon {
        transition: all 0.2s ease-in;
      }

      .toggle {
        transform: rotate(180deg);
      }

    }

    .menu-container {
      .menu-item {
        display: flex;
        align-items: center;
        column-gap: $spacing-8;
        cursor: pointer;
        padding: $spacing-base / 1.5 $spacing-base * 1.25;
        transition: background-position 0.2s;
        background-position: right;
        background-size: 200% 200%;
        background-image: linear-gradient(to right, $bg-blue 50%, transparent 0%);

        &:hover {
          text-decoration: none;
          background-position: left;
          color: #212529;
        }
      }
    }
  }

  .aside-user-toggle {
    min-height: 200px;
  }

  .menu-header {
    //border-bottom: 2px solid $bg-navy;
    box-shadow: 0 1px 5px 0 rgba(33, 45, 55, 0.5);
    padding-top: $spacing-base * 1.4;
    padding-right: $spacing-base;
    padding-bottom: $spacing-base * 0.3;
    padding-left: $spacing-base;
    opacity: 0;
    animation: fade 250ms ease forwards;

    :deep(.btn-back) {
      font-family: $font-family-Rubik;
      font-size: $font-size-base * 1.45;
      font-weight: $font-weight-bold;
      padding-left: 6px;
      color: $bg-navy;

      .fas {
        margin-right: $spacing-base * 0.6;
      }
    }
  }
}

@media screen and (min-width: 640px) and (max-width: $max-layout-lg) {
  .aside-container {
    width: 320px;
  }
}

@media screen and (max-width: 320px) {
  .aside-container {
    width: 100vw;
  }
}

@keyframes fade {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes slide-left {
  0% {
    opacity: 0;
    transform: translateX(-50%);
  }

  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

.nav-item-token {
  text-align: center;
  margin-bottom: 14px;

  a {
    border: 1px solid #dadce0;
    padding: 8px 16px;
    border-radius: 100px;
    color: $body-color;
    text-decoration: none;
    font-size: 12px;
    transition: all 250ms ease;

    i {
      margin-right: 4px;
    }

    &:hover {
      text-decoration: none;
      background-color: $grey-200;
    }
  }
}
</style>
