<template>
  <div
    :class="{'profile-nav-container': nav }"
    class="profile-container">
    <div class="profile-header">
      <div class="profile-image">
        <Logo
          :user="user"
          :options="displayImageOption" />
      </div>
      <div class="profile-name">
        <h3>{{user.name}}</h3>
        <p class="email"><i class="fas fa-envelope"></i> {{user.email}}</p>
        <p class="team"><i class="fas fa-briefcase"></i> <span class="role">{{user.role}}</span> {{$t('profile.teamName', {teamName:user.team.name})}}</p>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import Logo from '@/modules/shared/components/atoms/logo/Logo.vue';

export default {
  props: {
    nav: {
      type: Boolean,
      required: false,
      default: () => false,
    },
  },
  components: {
    Logo,
  },
  data() {
    return {
      displayImageOption: {
        width: '64px',
        height: '64px',
        fontSize: '28px',
      },
    };
  },
  computed: {
    ...mapState({
      user: (state) => state.user.currentUser,
    }),
  },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/global-variables.scss';
@import '~@/assets/scss/breakpoint.scss';

.profile-container {
  .profile-header {
    display: flex;
    flex-flow: column;
    width: 100%;
    justify-content: center;
    align-items: center;
    margin-top: 16px;

    .profile-image {
      .logo {
        width: 128px !important;
        height: 128px !important;
        border-radius: 50%;
        margin-bottom: 10px;
        margin-top: 10px;
      }
      img {
        width: 128px;
        height: 128px;
      }
    }
    .profile-name {
      text-align: center;
      h3 {
        //line-height: 1.2;
      }
      p {
        margin-top: $spacing-4;
      }
      .team {
        margin-bottom: $spacing-base;
        .role {
          text-transform: capitalize;
        }
      }
    }
  }
  &.profile-nav-container {
    cursor: default;
    .profile-header {
      .profile-image {
        .logo {
          width: 64px !important;
          height: 64px !important;
          border-radius: 50%;
          margin-bottom: 10px;
          margin-top: 0;
        }
        img {
          width: 64px;
          height: 64px;
        }
      }
      .profile-name {
        text-align: center;
        h3 {
          font-size: 18px;
          color: $grey-700;
        }
        p {
          color: $grey-600;
          font-size: 12px;
          margin-bottom: 4px;
        }
      }
    }
  }
}

@media screen and (max-width: $max-layout-sm) {
  .profile-container {
    padding-bottom: 30px;
  }
}
</style>
