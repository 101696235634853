<template>
  <div
    v-if="!isCollapse"
    class="project-select">
    <SelectOption
      :modelValue="selectedProject"
      @update:model-value="onSelectProject"
      :options="projectOptions"
      placeholder="Select Project"
    />
  </div>
  <MenuList title="Contents" :isCollapse="isCollapse">
    <MenuItem
      title="Videos"
      iconClassName="fas fa-play-circle"
      :isCollapse="isCollapse"
      :path="getMenuPath('videos')"
    />
    <MenuItem
      v-if="projectKey"
      title="Upload"
      iconClassName="fas fa-cloud-arrow-up"
      :isCollapse="isCollapse"
      :path="getMenuPath('upload')"
    />
    <MenuItem
      v-if="projectKey"
      title="Import"
      iconClassName="fas fa-file-import"
      :isCollapse="isCollapse"
      :path="getMenuPath('import')"
    />
    <MenuItem
      v-if="projectKey"
      title="Collections"
      iconClassName="fas fa-rectangle-history"
      :isCollapse="isCollapse"
      :path="getMenuPath('collections')"
    />
    <MenuItem
      title="Issues"
      iconClassName="fas fa-bell"
      :isCollapse="isCollapse"
      :path="getMenuPath('issues')"
    />
    <MenuItem
      v-if="projectKey"
      title="Exported Files"
      iconClassName="fa fa-file-export"
      :isCollapse="isCollapse"
      :path="getMenuPath('exported-files')">
      <template v-slot:menu-item-suffix>
        <Badge
          :title="'beta'"
          :badgeStyle="'highlight'"
          size="small" />
      </template>
    </MenuItem>
    <MenuItem
      title="Recently Deleted"
      iconClassName="fas fa-trash-can"
      :isCollapse="isCollapse"
      :path="getMenuPath('recently-deleted')"
    />
  </MenuList>
  <MenuList
    v-if="projectKey && (!loadProjectState?.error || loadProjectState?.error?.statusCode !== 403)"
    title="Project Management"
    :isCollapse="isCollapse">
    <MenuItem
      title="Members"
      iconClassName="fas fa-users"
      :isCollapse="isCollapse"
      :path="getMenuPath('membership')"
    />
    <MenuItem
      title="Manage Project"
      iconClassName="fas fa-gear"
      :isCollapse="isCollapse"
      :path="!isResponsive && getMenuPath('manage-project')"
      @click.prevent="emit('update:currentPage','projectManage')"
    >
      <template v-slot:menu-item-suffix v-if="isResponsive">
        <i class="fas fa-chevron-right" />
      </template>
    </MenuItem>
  </MenuList>
</template>

<script lang="ts" setup>
import type { Nullable } from '@/types/index.type';
import type { Project } from '@/modules/shared/types/project.type';
import {
  computed, ref, onBeforeMount, watch, toRefs,
} from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useI18n } from 'vue-i18n';
import MenuItem from '@/modules/shared/components/molecules/menuItem/MenuItem.vue';
import MenuList from '@/modules/shared/components/molecules/menuList/MenuList.vue';
import SelectOption from '@/modules/shared/components/atoms/selectOption/SelectOption.vue';
import Badge from '@/modules/shared/components/atoms/badge/Badge.vue';
import { useStore } from '../../../store/hooks';

interface ProjectVideosPageNavMenuProps {
  isCollapse?: boolean;
  isResponsive?: boolean;
}

const props = withDefaults(defineProps<ProjectVideosPageNavMenuProps>(), {
  isCollapse: false,
  isResponsive: false,
});

const { isCollapse, isResponsive } = toRefs(props);

const emit = defineEmits<{
  'update:currentPage': [value: string];
}>();

const route = useRoute();
const router = useRouter();
const store = useStore();
const { t } = useI18n();

const teamName = computed(() => route?.params?.teamName);
const projectKey = computed(() => route?.params?.projectKey);
const projectList = computed(() => store.state.projectList.projectList);

interface ProjectOption extends Partial<Project> {
  isAllProject?: true;
}

const allProjectItem: ProjectOption = {
  name: t('project.all.title'),
  isAllProject: true,
  key: '',
};

const projectOptions = computed<ProjectOption[]>(() => [
  allProjectItem,
  ...(projectList.value ?? []),
]);

const loadProjectState = computed(() => store.state.project.loadProjectState);

function getSelectedProject(): ProjectOption | undefined {
  if (projectKey.value) {
    return projectOptions.value.find((project) => project.key === projectKey.value);
  }

  return allProjectItem;
}

const selectedProject = ref<Nullable<ProjectOption>>(getSelectedProject());

watch(projectKey, () => {
  selectedProject.value = projectList.value && projectOptions.value.find((item) => item.key === projectKey.value);
});

const onSelectProject = (value: ProjectOption) => {
  selectedProject.value = value;

  if (selectedProject.value.isAllProject) {
    router.push(`/${teamName.value}/all-projects`);
  } else {
    router.push(`/${teamName.value}/projects/${selectedProject.value.key}`);
  }
};

const getMenuPath = (menu: string): string => {
  const basePath = projectKey.value ? `/${teamName.value}/projects/${projectKey.value}` : `/${teamName.value}/all-projects`;

  switch (menu) {
    case 'videos':
      return `${basePath}`;
    case 'issues':
      return `${basePath}/video-issues`;
    case 'recently-deleted':
      return `${basePath}/recently-deleted`;
    case 'membership':
      return `${basePath}/manage/members`;
    case 'manage-project':
      return `${basePath}/manage/general`;
    case 'import':
      return `${basePath}/imports`;
    default:
      return `${basePath}/${menu}`;
  }
};

const loadProjectList = async () => {
  if (!projectList.value) {
    await store.dispatch('loadProjectList');
  }
};

onBeforeMount(async () => {
  await loadProjectList();
});

watch(projectOptions, () => {
  selectedProject.value = getSelectedProject();
}, { immediate: true });
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/global-variables.scss';
@import '~@/assets/scss/breakpoint.scss';

.project-select {
  padding: 0px $spacing-base;
  margin-top: $spacing-24;

  @media screen and (max-width: $max-layout-lg) {
    margin-top: 12px;
  }
}

:deep(.fa-file-export) {
  margin-right: -4px;
}

</style>
